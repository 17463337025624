import { NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import { actions } from './financial'

const schema = [
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        name: 'monthly_costs',
        label: 'Maandelijks forfait',
        placeholder: 'Maandelijks forfait',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'commission',
        label: 'Maandelijkse provisie',
        placeholder: 'Maandelijkse provisie',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        type: 'translatable',
        name: 'monthly_costs_description',
        label: 'Maandelijks forfait - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'translatable',
        name: 'commission_description',
        label: 'Maandelijkse provisie - extra informatie',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  }
]

export default {
  heading: 'Vaste kosten',
  schema,
  actions
}
